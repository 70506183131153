import styled from "styled-components"
import { md } from "mill/utils/breakpoints"
import colors from "shared/utils/colors"

export const Frame = styled.div`
  margin: 0 auto;
  overflow: hidden;
  background: white;
`

export const Question = styled.h2`
  padding: 3rem 0;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 600;

  @media ${md} {
    font-weight: 500;
    font-size: 2.4rem;
    border-bottom: none;
    padding: 3rem 0;
    text-align: center;
  }
`

export const CorrectAnswer = styled.div`
  margin-top: 1rem;
  text-align: center;
  text-transform: uppercase;
  padding: 1.5rem 1.5rem;
  display: block;
  text-align: left;
  background-color: #a4e5db;
  color: #535353;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0 -1.5rem;

  @media ${md} {
    font-size: 1.5rem;
    margin: 0;
    padding: 0 4rem;
  }
`

export const QuestionContainer = styled.div`
  padding: 0 1.5rem;

  @media ${md} {
    border: 2px solid #f1f1f1;
    border-top: none;
    padding: 0 4rem;
  }
`

export const ExplanationContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  @media ${md} {
    display: flex;
    flex-direction: row;
    border: 2px solid #f1f1f1;
    margin-top: 3px;
  }
`

export const ExplanationHeading = styled.h3`
  display: none;

  @media ${md} {
    display: block;
    text-transform: uppercase;
    color: ${props => props.theme.colors.primary};
    font-family: "Raleway";
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1.8rem;
  }
`

export const AnswerLabel = styled.div`
  display: none;
  @media ${md} {
    display: block;
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Raleway";
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #f1f1f1;
    white-space: nowrap;
  }
`
export const Answers = styled.div`
  @media ${md} {
    display: flex;
    max-width: 100rem;
    margin: 0 auto 3rem auto;
    min-height: 8rem;
    background-color: #a4e5db;

    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      min-height: 8rem;
    }
  }
`

export const Explanation = styled.div`
  padding: 0 1.5rem;

  @media ${md} {
    flex-grow: 1;
    max-width: 80%;

    > div {
      padding: 1rem 4rem 3rem 4rem;
    }
  }
`

export const Sidebar = styled.div`
  @media ${md} {
    background-color: #f1f1f1;
    padding: 1rem 3rem 3rem 3rem;
    width: 35%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  p {
    color: ${colors.grayMatterhorn};
    width: 100%;
  }
`

export const ResourceLinkContainer = styled.div`
  background-color: #f1f1f1;
  padding: 1rem 2rem;
  font-size: 0.8em;
  text-align: left;
`

export const ProvideFeedback = styled.div`
  color: ${props => props.theme.colors.primary};

  @media ${md} {
    margin-bottom: 1.5rem;
  }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.primaryHover};
  }

  i {
    margin-right: 0.5rem;
  }

  p {
    display: inline;
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`
