import styled from "styled-components"
import { lightGray } from "mill/utils/colors"

export const ModalContainer = styled.div`
  flex: 1;
  background: white;
  border: 3px solid ${lightGray};
  padding: 2rem;
  position: relative;
`

export const Header = styled.div`
  border-bottom: 1px solid #d8d8d8;

  i {
    color: ${props => props.theme.colors.primary};
    font-size: 4rem;
    margin-right: 1rem;
    vertical-align: middle;
    margin-bottom: 2rem;
  }

  div {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2rem;
  }

  h2 {
    display: inline-block;
    color: ${props => props.theme.colors.primary};
    font-family: Raleway;
    font-size: 2.2rem;
    margin: 0;
  }

  p {
    font-size: 1.3rem;
    margin: 0;
    color: #a3a5a6;
  }
`
