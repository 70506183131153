import React from "react"
import { Formik, Form, Field } from "formik"
import styled from "styled-components"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import { FormikTextAreaField as TextArea } from "mill/components/TextArea"
import Button from "shared/components/Button/index.legacy.js"
import { FeedbackSchema } from "mill/utils/schemas"

export const FieldGroup = styled.div`
  label {
    color: #959595;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`

const FeedbackForm = ({ submitFeedback, labelText, toggleModal }) => {
  return (
    <Formik
      initialValues={{
        feedback: ""
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        submitFeedback(values.feedback)
        actions.setSubmitting(false)
      }}
      validationSchema={FeedbackSchema}>
      {({ isSubmitting, submitCount, errors }) => {
        const hasErrors = !(Object.keys(errors).length === 0)

        if (submitCount > 0) {
          return (
            <Box paddingTop="medium">
              <Box paddingBottom="small">
                <Text>
                  Thanks for your feedback! This will be provided to your
                  account administrator, and used to improve future content.
                </Text>
              </Box>
              <Button onClick={toggleModal} label="Close" />
            </Box>
          )
        }

        return (
          <Form>
            <FieldGroup>
              <Field
                withFieldset
                fullWidth
                name="feedback"
                data-qa="feedback"
                component={TextArea}
                label={labelText}
                placeholder="I have some feedback..."
              />
            </FieldGroup>
            <Button
              data-qa="submit-feedback"
              color="secondary"
              type="submit"
              disabled={isSubmitting || hasErrors}
              label="Submit feedback"
              style={{ marginTop: "2rem" }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default FeedbackForm
